<template>
  <div class="requests-form-edit">
    <div>
      <h2 class="crate-request-header">{{ $t('Create request') }}</h2>
      <div class="content">
        <form action="" @submit.prevent="saveRequest">

          <md-card-content>
            <p class="field-name">{{ $t('title') }}*</p>
            <md-field md-inline class="title-field hidden-title-field">
              <md-input v-model="title" class="input-title"></md-input>
              <span class="generic-date">{{ chronoNumberWithName }}</span>
            </md-field>
          </md-card-content>

          <md-card-content class="description-content">
            <p class="field-name">{{ $t('Description') }}*</p>
            <md-field md-inline>
              <md-textarea v-model="description" required :placeholder="`${$t('Description')}*`"></md-textarea>
            </md-field>
          </md-card-content>

          <md-card-content class="select-wrap">
            <p class="field-name">{{ $t('Priority') }}*</p>
            <select name="priority" class="select-priority" v-model="selected_priority">
              <option :selected="selected_priority === priority" v-for="priority in prioritiesList" :key="priority"
                      :value="priority">{{ $t(priority) }}
              </option>
            </select>
          </md-card-content>

          <md-card-content class="select-wrap">
            <p class="field-name">{{ $t('Nature') }}*</p>
            <select name="nature" class="select-nature" v-model="selected_nature">
              <option :selected="selected_nature === nature" v-for="nature in naturesList" :key="nature"
                      :value="nature">{{ $t(nature) }}
              </option>
            </select>
          </md-card-content>

            <md-card-content class="select-wrap">
                <p class="field-name">{{ $t('Verification') }}</p>
                <select name="nature" class="select-nature" v-model="selected_verification">
                    <option v-for="verification in verificationsList" :key="verification"
                            :value="verification">{{ verification }}
                    </option>
                </select>
            </md-card-content>

          <md-card-content class="select-wrap">
            <p class="field-name">{{ $t('status') }}</p>
            <select name="statuses" class="select-statuses" v-model="selected_status" :disabled="true">
              <option v-for="status in statusesList" :key="status" :value="status">{{ (status) }}</option>
            </select>
          </md-card-content>
          <div v-for="(addFile, i) in addFiles" :key="i+'num'">
            <md-card-content class="form-field flex-center upload-field">
              <p class="field-name">{{ $t('AddFile') }} {{ i + 1 }}</p>
              <md-field :class="{'uploaded': hasUploadedFile}">
                <md-file
                    @change="uploadDocument"
                    :placeholder="`${$t('SelectFile')}`"
                    class="uploadDocumentEdit"
                    :value="fileName"
                    multiple
                    files="true"
                ></md-file>
              </md-field>
              <div class="icons-wrapper">
                <div v-if="addFiles.length === i+1"
                     class="btn-plus pointer"
                     @click="addDocumentFiles(addFile.id)"
                >
                  <img src="../../assets/images/plus.svg"/>
                </div>

                <div class="btn-close pointer " v-if="addFiles.length > 1" @click="removeAddFiles(i)">
                  &#10006;
                </div>
              </div>
            </md-card-content>
          </div>
          <div class="actions">
            <md-button class="md-raised save" type="submit"><span>{{ $t('save') }}</span></md-button>
            <md-button class="md-raised cancel">
              <router-link to="/requests"><span>{{ $t('cancel') }}</span></router-link>
            </md-button>
          </div>

        </form>
      </div>
      <div>

        <p v-if="fileErrors[0]" class="fileErrors">{{ fileErrors[0] }}</p>
        <p class="field-name"></p>
        <Messages :messages="messages"></Messages>
        <Errors :errors="errors"></Errors>

      </div>
    </div>
  </div>
</template>

<script>
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import {printArray} from "@/mixins/printArray"
import {userPermissions} from '@/mixins/userPermissions';
import {mapActions, mapGetters} from 'vuex';
import Errors from "@/components/Errors"
import Messages from "@/components/Messages"

export default {
  name: "requests-form-create",
  mixins: [
    printArray,
    errors,
    messages,
    userPermissions
  ],
  components: {
    Errors,
    Messages
  },
  data: () => ({
    chronoNumberWithName: '',
    title: '',
    description: '',
    natures: [],
    permissions: [],
    role_name: '',
    selected_priority: '',
    selected_nature: '',
    selected_status: '',
    selected_verification: '[Empty]',
    selected_request: '',
    documents: [],
    requestsListData: [],
    getLinkedRequests: '',
    fileName: '',
    fileErrors: [],
    allDocuments: [],
    documents_id: [],
    hasNewFile: false,
    linkedRequests: [{
      id: 1,
      text: ''
    }],
    addFiles: [{
      id: 1,
      text: ''
    }],
  }),

  computed: {
    ...mapGetters(['prioritiesList', 'naturesList', 'statusesList', 'verificationsList','requestsList', 'userData', 'reqCountClientWithAllStatuses']),
    hasUploadedFile() {
      return this.documents_id.length > 0
    },
  },


  watch: {
    $route() {
      this.clearErrors()
      this.clearMessages()
    },
    chronoNumberWithName(val) {
      if(val){
        setTimeout(() => {
          const newWidth = document.getElementsByClassName('generic-date')[0].clientWidth
          document.getElementsByClassName('input-title')[0].style.paddingLeft = newWidth + 15 +"px";
          document.getElementsByClassName('hidden-title-field')[0].style.opacity = 1;
        }, 500);
      }
    },

    userData: {
      deep: true,
      handler() {
        this.getStatusesList()
        this.getVerificationsList()
        this.selected_priority = this.$t(this.selected_priority)
        this.selected_status = this.$t(this.selected_status)
        this.selected_verification = this.$t(this.selected_verification)
        if ( this.userData.language === 'ru' ){
          this.selected_status = 'Новый'
          this.selected_verification = '[Пустой]'
        }
        if ( this.userData.language === 'en' ){
          this.selected_status = 'New'
          this.selected_verification = '[Empty]'
        }
      }
    }
  },


  async mounted() {

    await this.getVerificationsList()
    await this.getPrioritiesList()
    await this.getNaturesList()
    await this.getStatusesList()
    // await this.getVerificationsList()

    if (this.userData.roles[0].name === 'client') {

      await this.getClientRequestsWithAllStatuses()
          .then(data => {
            this.requestsListData = data
            const chrono = this.reqCountClientWithAllStatuses ? this.reqCountClientWithAllStatuses+1 : '1'
            this.chronoNumberWithName = this.setChronoNumber(chrono)

            document.querySelector('input').style.padding = `0 0 0 ${190 + this.chronoNumberWithName.length}px`
          })
          .catch(err => {
            console.log(err)
          })
      this.admin = false
    } else {
      await this.getRequestsList()
          .then(data => {
            this.requestsListData = data
          })
          .catch(err => {
            console.log(err)
          })
    }
    if ( this.userData.language === 'ru' ){
      this.selected_status = 'Новый'
      this.selected_verification = '[Пустой]'
    }
    if ( this.userData.language === 'en' ){
      this.selected_status = 'New'
      this.selected_verification = '[Empty]'
    }

    this.selected_priority = this.$t(this.selected_priority)
    // this.selected_verification = this.$t(this.selected_verification)
    this.selected_nature = this.$t(this.selected_nature)
  },

  methods: {
    ...mapActions(['getPrioritiesList', 'getNaturesList', 'getVerificationsList', 'getStatusesList', 'getCreateRequest', 'getRequestsList', 'getClientRequestsWithAllStatuses']),
    async saveRequest() {
      this.clearErrors()
      this.clearMessages()
      if (this.userData.roles[0].name === 'client'){
        await this.getClientRequestsWithAllStatuses()
      }else {
        await this.getRequestsList()
      }
      const requests_related = [];
      const response = await this.uploadFile();
      if (response !== 'success') {
        return
      }

      this.linkedRequests?.forEach(linked => {
        if (linked.text) requests_related.push(linked.text);
      });


      const chrono = this.reqCountClientWithAllStatuses ? this.reqCountClientWithAllStatuses+1 : '1'

      this.chronoNumberWithName = this.setChronoNumber(chrono)

      let data = {
        priorities_id: this.priorities_id,
        title: `${this.chronoNumberWithName} ${this.title}`,
        description: this.description,
        status: this.$t(this.selected_status),
        nature: this.selected_nature,
        priority: this.selected_priority,
        verification_level: this.$t(this.selected_verification),
        requests_related: requests_related,
        documents_id: this.documents_id,
      };

      const request = await this.getCreateRequest(data)
      if (request.status === 'success') {
        this.$router.push({name: 'requests-list'})
      } else {
        this.setErrors(request.errors)
      }

    },
    addLinkedRequest(id) {
      this.linkedRequests.push({
        id: id + 1,
        text: ''
      })
    },

    addDocumentFiles(id) {
      this.addFiles.push({
        id: id + 1,
        text: ''
      })
    },
    removeLinkedRequest(i) {
      this.linkedRequests.splice(i, 1)
    },

    removeAddFiles(i) {
      this.addFiles.splice(i, 1)
    },
    async uploadDocument() {
      this.fileErrors = []
      if (event.target.files) {
        let bigSize = false;
        event.target.files.forEach(file => {
          const size = file.size / 1024 / 1024;
          if (size > 25) {
            bigSize = true;
          }
        })

        if (bigSize) {
          this.fileErrors[0] = 'The maximum size for a file is 25 Mb';
          event.target.value = '';
          this.fileName = 'Select file';
          this.documents = [];
        } else {
          this.documents.push(...event.target.files);
          this.fileErrors = [];
        }
      }
    },
    async uploadFile() {
      const url = process.env.VUE_APP_API_URL + '/requests/documents/create/several';
      const formData = new FormData();

      this.hasNewFile = false
      this.documents.forEach(file => {
        if (!file.id) {
          this.hasNewFile = true
          formData.append(`document[]`, file);
          this.fileName = 'Loading...';
        }
      })

      if (!this.hasNewFile) {
        return 'success'
      }

      return await this.$axios
          .post(url, formData)
          .then(response => {
            this.hasNewFile = false
            this.documents.forEach(file => {
              this.allDocuments.push(file)
            })
            this.fileName = 'Select file';
            this.documents_id.push(...response.data.ids);

            this.allDocuments.map((doc, i) => {
              doc.id = this.documents_id[i]
            })
            return response.data.status
          })
          .catch(err => {
            this.hasNewFile = false
            this.documents = [];
            this.fileName = 'Select file';
            const errors = err.response.data.errors;
            for (let errorsKey in errors) {
              this.documents = [];
              this.fileErrors.push(errors[errorsKey].toString())
            }
            // this.loading = false;
          })
    },
    download(file) {
      if (!file.id) return;
      this.$axios({
        url: process.env.VUE_APP_API_URL + `/requests/documents/${file.name}/downloadFile`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', file.name);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    getUpdatedDate(date) {
      return this.$moment(date).format('DD/MM/YYYY')
    },

    setChronoNumber (number) {
      number = number.toString()
      const date = new Date();
      let dynamicName = ''

      let zerosCount = Math.abs(number.length - 5);
      for (let i = 0; i < zerosCount; i++) {
        dynamicName += '0'
      }
      return `${this.userData.client.name}-${date.getFullYear()}-${date.getMonth()+1}-${dynamicName}${this.reqCountClientWithAllStatuses + 1}`
    }
  }
}
</script>

<style lang="scss">
.requests-form-edit .md-field .md-input {
  position: relative;
}
.hidden-title-field{
  opacity: 0;
}
.generic-date {
  position: absolute;
  top: 15px;
  left: 13px;
  font-size: 16px;
}

.select-statuses:disabled {
  opacity: 1 !important;
  color: #000000;
}

.requests-form-edit {
  margin-left: 21px;

  p {
    margin: 0;
  }

  form {
    width: 45%;
  }

  .md-input, .md-textarea {
    font-size: 16px;
  }

  .select-wrap, .request-wrap {
    margin-bottom: 20px;
  }

  .select-nature, .select-priority, .select-request, .select-statuses {
    font-size: 16px;
  }

  .btn-close, .btn-plus {
    cursor: pointer;
  }


  .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
    border-color: white !important;
  }

  .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
    border-color: white !important;
  }

  .md-tabs.md-theme-default .md-tabs-navigation {
    background: unset;
    border-bottom: 2px solid grey;
  }

  .md-tabs.md-theme-default .md-tabs-indicator {
    background-color: #054F63 !important;
    top: 48px;
  }

  .md-tabs.md-theme-default .md-tabs-navigation .md-button {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .description-content {
    margin: 20px 0 !important;
  }

  .name-disable {
    background: #939393;
    opacity: .5;
  }

  .md-content.md-theme-default {
    background: unset;
  }

  .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  .select-role, .select-nature, .select-priority, .select-statuses, .select-request {
    width: 68%;
    max-width: 418px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    outline: none;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 10px;
    height: 50px;
    padding: 0;
    padding-left: 25px;

    option {
      height: 50px;
    }
  }

  .content {
    display: flex;
  }

  .btn-plus {
    height: 50px !important;
    display: flex !important;
    align-items: center !important;
    margin-right: 7px;
  }

  .actions {
    margin-left: 128px;
    display: flex;
    width: 63%;
    max-width: 418px;

    @media(max-width: 1440px) {
      display: flex;
      justify-content: space-between;
    }

    .save {
      background: #EC8B31 !important;
      border-radius: 6px;

      span {
        color: white;
      }
    }

    .cancel {
      margin-left: 10px;
      border-radius: 6px;

      @media(max-width: 1440px) {
        margin-left: 0;
      }

      span {
        color: black;
      }
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 12px 32px;
    }
  }

  .md-ripple {
    .md-icon.md-theme-default.md-icon-font {
      display: none !important;
    }
  }

  .md-menu.md-select {
    padding-left: 25px;
    padding-top: 5px;
  }

  .md-card-content {
    //margin-left: 21px;
    display: flex;
    padding: 0 !important;
  }

  .edit-text {
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
  }

  .md-field:after, .md-field:before {
    position: unset !important;
  }

  .md-card-content {
    width: 100%;
    //justify-content: space-evenly;

    p {
      padding-top: 17px;
      width: 130px;
      text-align: right;
    }

    //.md-has-password {
    //  margin-left: -20px;
    //}
  }

  .md-card.md-theme-default {
    background: unset;
    width: 50%;
  }

  .md-card {
    box-shadow: unset !important;
  }

  .md-select-value {
    position: absolute;
    top: 6px;
    left: 25px;
  }

  .md-field {
    width: 68%;
    max-width: 418px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 10px 0 15px !important;
    padding: 0;

    .md-input {
      height: 50px !important;
      padding: 0 0 0 13px;
      margin-right: 60px !important;
    }


    label {
      position: absolute;
      top: 14px;
      padding-left: 25px;
    }
  }

  .permissions {

    h5 {
      //margin-left: 186px;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 34px;
      color: #000000;

      @media(max-width: 1440px) {
        margin-left: 100px;
      }
    }

    ul {
      margin-left: 150px;

      li {
        display: block;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }

  .fileErrors {
    color: red;
  }

  .files-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .file-box {
      width: 120px;
      background-color: #ffffff;
      padding: 10px 15px;
      border: 1px solid #D8D8D8;
      border-radius: 5px;
      margin-right: 10px;
      margin-bottom: 10px;

      .close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        img {
          cursor: pointer;
        }
      }

      .file-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
      }

      .file-name {
        text-decoration: none;
        margin: 10px 0 5px;
        font-weight: normal;
        font-size: 12px;
        line-height: 1;
        word-break: break-all;
        /*height: 28px;*/
        width: 100px;
        text-align: center;
        color: black;
      }
    }
  }

  .upload-field {
    margin-bottom: 20px;
  }

  .uploadDocumentEdit {
    border: none !important;

    i {
      position: absolute !important;
      right: 0 !important;
      height: 48px;
      border-left: 1px solid #CDD0D9;
      width: 12%;
    }
  }

}

.requests-form .md-tabs.md-theme-default .md-tabs-navigation {
  border-bottom: none !important;
}

.md-tabs-navigation {
  display: none !important;
}

.crate-request-header {
  margin-bottom: 20px;
  font-weight: bold !important;
  font-size: 36px !important;
  height: 35px;
}

.md-textarea {
  padding: 10px 16px !important;
}

.md-field.md-textarea {
  height: 230px !important;
  min-height: 230px !important;
}

.icons-wrapper {
  display: flex;
  align-items: center;
}

.uploadDocumentEdit {
  .md-icon-image {
    svg {
      height: 60%;
    }
  }
}


</style>
<style lang="scss">
.md-file {
  display: flex;
  flex-direction: column;
  height: 48px;
  flex: 1;
  input[type=file] {
    width: 100% !important;
    height: 48px !important;
    position: absolute;
    top: 21px;
    clip: auto;
    opacity: 0;
  }
}
</style>